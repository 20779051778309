<template>
  <b-card no-body class="border" style="width:100%;height:100%">
    <b-card-header class="p-1">
    <div>
        <b-card-title>
       {{chartTitle}}
      </b-card-title>
      </div>
      <div class="d-flex align-items-center"  v-if="eliminar==1">
          <feather-icon
          icon="XIcon"
          size="16"
           class="cursor-pointer"
           @click="DeleteChart"
        />
      </div>
    </b-card-header>
        <vue-apex-charts
        type="line"
        height="80%"
        :options="chartOptions"
        :series="series"
      />

  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,BModal,BCardText,BSpinner
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  props:["chartTitle","cseries","labels","idref","eliminar"],
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,BModal,BCardText,BSpinner
  },
  data() {
    return {
       modalShow: false,
        series:this.cseries,
         chartOptions: {
            chart: {
              height: 350,
              type: 'line',
               toolbar: {
          show: false,
          },
           animations: {
        enabled: false
    }
            },
               legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
            stroke: {
              curve: 'smooth',
              width:1.5
            },
              grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
         yaxis: {
          lines: {
            show: false,
          },
        },
      },
            fill: {
              type:'solid',
              opacity: [0.35, 1],
            },
            labels:this.labels,
            markers: {
              size: 0
            },
             xaxis: {
             tickAmount:4,
             labels: {
                  rotate: 0,
                  rotateAlways: false,
              }
              },
            yaxis: [
              {
                title: {
                  text: this.cseries.length>0?this.cseries[0]['name']:undefined,
                },
                 labels:{
               formatter: function (params) {
      var pow = Math.pow,
        floor = Math.floor,
        abs = Math.abs,
        log = Math.log;
      var n = params;
      var abbrev = "KMB";
      var base = floor(log(abs(n)) / log(1000));
      var suffix = abbrev[Math.min(2, base - 1)];
      base = abbrev.indexOf(suffix) + 1;
      var n2 = n / pow(1000, base);
      var prec = Math.pow(10, 2);
      var n3 = Math.round(n2 * prec) / prec;
      return suffix ?n3+suffix :n; 
      }
      }
              },
              {
                opposite: this.cseries.length>1?true:undefined,
                title: {
                  text:this.cseries.length>1?this.cseries[1]['name']:undefined,
                },
                 labels:{
                  show:this.cseries.length>1?true:false,
               formatter: function (params) {
      var pow = Math.pow,
        floor = Math.floor,
        abs = Math.abs,
        log = Math.log;
      var n = params;
      var abbrev = "KMB";
      var base = floor(log(abs(n)) / log(1000));
      var suffix = abbrev[Math.min(2, base - 1)];
      base = abbrev.indexOf(suffix) + 1;
      var n2 = n / pow(1000, base);
      var prec = Math.pow(10, 2);
      var n3 = Math.round(n2 * prec) / prec;
      return suffix ?n3+suffix :n; 
      }
      }
              },
            ],
            tooltip: {
              shared: true,
              intersect: false
            }
          },

    }
  },
     watch:{
    labels:function(e){
       this.chartOptions= {    
             labels:e,
          }
    },
    cseries:function(e){
      this.series=e
    }
  },
   methods:{
    DeleteChart:function(){
       this.$root.$emit("chartidref",this.idref);
    }
  }
}
</script>
