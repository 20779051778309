<template>
  <b-card no-body class="border" style="width:100%;height:100%">
    <b-card-header class="p-1">
      <div>
        <b-card-title>
          {{chartTitle}}
        </b-card-title>
      </div>
       <div  class="d-flex align-items-center"  v-if="eliminar==1">
          <feather-icon
          icon="XIcon"
          size="16"
           class="cursor-pointer"
           @click="DeleteChart"
        />

      </div>
    </b-card-header>
           <vue-apex-charts
        type="area"
        height="80%"
        :options="chartOptions"
        :series="series"
      />
  </b-card>
  
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BModal,BCardText,BSpinner} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,BModal,BCardText,BSpinner 
  },
  props:["chartTitle","cseries","labels","estado","idref","eliminar"],
  data() {
    return {
      modalShow: false,
        series: this.cseries,
          chartOptions: {
            chart: {
              toolbar: {
          show: false,
          },
            animations: {
        enabled: false
    }
          },    
            dataLabels: {
              enabled: false
            },
            legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
            stroke: {
               show: this.estado,
              curve: 'smooth',
              width:1
            },
             grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
            yaxis:{
              labels:{
               formatter: function (params) {
      var pow = Math.pow,
        floor = Math.floor,
        abs = Math.abs,
        log = Math.log;
      var n = params;
      var abbrev = "KMB";
      var base = floor(log(abs(n)) / log(1000));
      var suffix = abbrev[Math.min(2, base - 1)];
      base = abbrev.indexOf(suffix) + 1;
      var n2 = n / pow(1000, base);
      var prec = Math.pow(10, 2);
      var n3 = Math.round(n2 * prec) / prec;
      return suffix ?n3+suffix :n; 
      }
      }
        },
            xaxis: {
             categories:this.labels,
             tickAmount:this.labels.length>10?4:undefined,
             labels: {
                  rotate: 0,
                  rotateAlways: false,
              }
              },
          },
          
    }
  },
   watch:{
    labels:function(e){
       this.chartOptions= {    
            xaxis: {
             categories:e,
             tickAmount:e.length>10?2:undefined,
             labels: {
                  rotate: 0,
                  rotateAlways: false,
              }
              },
          }
    },
    cseries:function(e){
      this.series=e
    }
  },
   methods:{
    DeleteChart:function(){
       this.$root.$emit("chartidref",this.idref);
    }
  }
  }
</script>
